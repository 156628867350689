<app-layout></app-layout>


<div class="content">


    <div class="edit-profile">
        <div class="row justify-content-center">
          <div class="col-md-5">
            <form [formGroup]="passwordForm">
              <div class="change-pass">
                <div class="form-group">
                  <label for="">Name</label>
                  <mat-form-field appearance="fill" class="w-100">
                    <input matInput formControlName="name" (input)="checkFieldsEntered()">
                  </mat-form-field>
                </div>
      
                <div class="form-group"> 
                  <label for="">Email</label>
                  <mat-form-field appearance="fill" class="w-100">
                    <input matInput formControlName="email" >
                  </mat-form-field>
                </div>
      
                <div class="form-group text-center">
                  <button type="button" class="btn btn-blue" (click)="changePassword()" style="background-color: #0E103D;">
                    Update
                  </button>
                  <p class="pass-change-msg" *ngIf="passChangeMsg">Profile Updated Successfully!</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      
      
      

  