import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
  selector: 'app-manage-bookings',
  templateUrl: './manage-bookings.component.html',
  styleUrls: ['./manage-bookings.component.scss']
})
export class ManageBookingsComponent implements OnInit {
  currentPage:number;
  page:number;
  lang: string;
  imgURL: any;
  addId: any;

  constructor(
    private _header: HeaderService,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.lang = localStorage.getItem("lang");
    this._header.manageBookingHeading.next(true);
    this.imgURL = "../../../../../assets/icons/img-thumb.svg";
    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
    });

    if (this.page) {
      this.currentPage = this.page;
    }
  }

  ngOnDestroy() {
    this._header.manageBookingHeading.next(false);
  }

}
