<app-layout></app-layout>
<div class="content">
<h1></h1>
    <div>
        <button class="btn btn-primary" style="border-radius: 15px; margin-right: 10px;background-color: #0E103D;border: #0E103D;">Upcoming</button>
        <button class="btn btn-primary" style="border-radius: 15px; margin-right: 10px;background-color: #0E103D;border: #0E103D;">Ongoing</button>
        <button class="btn btn-primary" style="border-radius: 15px; margin-right: 10px;background-color: #0E103D;border: #0E103D;">Completed</button>
        <button class="btn btn-primary" style="border-radius: 15px; margin-right: 10px;background-color: #0E103D;border: #0E103D;">Canceled</button>
    </div>
    
    

    <div class="skill-tabs d-flex justify-content-between align-items-top">
        <div class="skill-tab-left">
            <div class="table-responsive">
                <table class="table mt-2 custom-table" aria-label="Description of the table" style="border-collapse: collapse;">
                    <thead class="table-header" style="background-color: #0E103D;">
                        <tr>
                            <th scope="col" class="header-text" style="border: 1px solid black;">S.No</th>
                            <th scope="col" class="header-text" style="border: 1px solid black;">Service Image</th>
                            <th scope="col" class="header-text" style="border: 1px solid black;">Service Title</th>
                            <th scope="col" class="header-text" style="border: 1px solid black;">Service Fee</th>
                            <th scope="col" class="header-text" style="border: 1px solid black;">Scheduled Date and Time</th>
                            <th scope="col" class="header-text" style="border: 1px solid black;">Allocated Service Provider with Profile Picture</th>
                            <th scope="col" class="header-text" style="border: 1px solid black;">Status</th>
                            <th scope="col" class="header-text" style="border: 1px solid black;">Canceled by</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Each row now includes borders for each cell -->
                        <tr>
                            <td style="border: 1px solid black;">1</td>
                        <td style="border: 1px solid black;"><img style="border-radius: 50%;height: 50px;width: 50px;" src="../../../assets/images/4753177.jpg" alt="Service Image" /></td>

                            <td style="border: 1px solid black;">Title1</td>
                            <td style="border: 1px solid black;">$67</td>
                            <td style="border: 1px solid black;">March 3rd,7.45 PM</td>
                            <td style="border: 1px solid black;"><img style="border-radius: 50%;height: 50px;width: 50px;" src="../../../assets/images/4753177.jpg" alt="Service Image" /></td>

                            <td style="border: 1px solid black;">Upcoming</td>
                            <td style="border: 1px solid black;">Customer</td>
                        </tr>
    
                        <tr>
                            <td style="border: 1px solid black;">2</td>

                            <td style="border: 1px solid black;"><img style="border-radius: 50%;height: 50px;width: 50px;" src="../../../assets/images/4753177.jpg" alt="Service Image" /></td>

                            <td style="border: 1px solid black;">Title2</td>
                            <td style="border: 1px solid black;">$78</td>
                            <td style="border: 1px solid black;">March 3rd,7.45 PM</td>
                            <td style="border: 1px solid black;"><img style="border-radius: 50%;height: 50px;width: 50px;" src="../../../assets/images/4753177.jpg" alt="Service Image" /></td>

                            <td style="border: 1px solid black;">Ongoing</td>
                            <td style="border: 1px solid black;">Service Provider</td>
                        </tr>
    
                        <tr>
                            <td style="border: 1px solid black;">3</td>
                            <td style="border: 1px solid black;"><img style="border-radius: 50%;height: 50px;width: 50px;" src="../../../assets/images/4753177.jpg" alt="Service Image" /></td>

                            <td style="border: 1px solid black;">Title3</td>
                            <td style="border: 1px solid black;">$78</td>
                            <td style="border: 1px solid black;">March 3rd,7.45 PM</td>
                            <td style="border: 1px solid black;"><img style="border-radius: 50%;height: 50px;width: 50px;" src="../../../assets/images/4753177.jpg" alt="Service Image" /></td>

                            <td style="border: 1px solid black;">Completed</td>
                            <td style="border: 1px solid black;">Customer</td>
                        </tr>
    
                        <tr>
                            <td style="border: 1px solid black;">4</td>

                            <td style="border: 1px solid black;"><img style="border-radius: 50%;height: 50px;width: 50px;" src="../../../assets/images/4753177.jpg" alt="Service Image" /></td>
                            <td style="border: 1px solid black;">Title4</td>
                            <td style="border: 1px solid black;">$7</td>
                            <td style="border: 1px solid black;">March 3rd,7.45 PM</td>
                            <td style="border: 1px solid black;"><img style="border-radius: 50%;height: 50px;width: 50px;" src="../../../assets/images/4753177.jpg" alt="Service Image" /></td>
                            <td style="border: 1px solid black;">Canceled</td>
                            <td style="border: 1px solid black;">Customer</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
</div>
