<app-layout></app-layout>
<div class="content" style="margin-top: 300px;">

  <div  style="text-align: center;">
    <img src="../../../assets/new images/quick.png" alt="Quick Image" style="display: block; margin: 0 auto;">
    <a href="https://sandbox.qbo.intuit.com/app/paymentsdeposits" target="_blank" class="btn btn-primary" style="background-color: #0E103D;border: #0E103D;">View Quick Books</a>

  </div>
</div>
  

