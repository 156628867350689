import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageSettingsService {
  url: any = environment.API_URL;


  constructor(
    private http: HttpClient

  ) { }


  updateProfile(data: any) {
    return this.http.post(this.url + 'api/v1/admin/update-profile', data);
  }
}
