<div class="header" style="background-color: #0E103D;">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1 style="color: white;">Dashboard</h1>  
        </ng-container>
        <h1 *ngIf="manageCaregiversHeading" style="color: white;">Manage Service Categories</h1>
        <h1 *ngIf="manageInvoiceHeading" style="color: white;">Manage Invoice</h1>

        
        <h1 *ngIf="manageVendorsHeading" style="color: white;">Manage Prokeeping's Staff</h1>
        <h1 *ngIf="manageCalenderHeading" style="color: white;">Calendar List</h1>
        <h1 *ngIf="manageAdonHeading" style="color: white;">Manage Ad-On Services</h1>


<h1 *ngIf="manageViewHeading" style="columns: white;">View Users</h1>
<h1 *ngIf="managePaymentHeading" style="columns: white;">View Payments</h1>
<h1 *ngIf="manageSettingsHeading" style="columns: white;">Update Profile</h1>
<h1 *ngIf="managePropertyHeading" style="columns: white;">Manage Properties</h1>
<h1 *ngIf="manageBookHeading" style="columns: white;">Service Bookings</h1>
<h1 *ngIf="manageBookviewHeading" style="columns: white;">Service Bookings</h1>
        <h1 *ngIf="manageHostHeading" style="color: white;">Manage Services</h1>
        <h1 *ngIf="manageSubAdminHeading" style="color: white;">Manage Sub-Admin</h1>
        <h1 *ngIf="manageBookingHeading" style="color: white;">Service Bookings</h1>
        <h1 *ngIf="manageClientHeading">Contact Us</h1>
        <h1 *ngIf="manageServicesHeading">Google Analytics</h1>
        <h1 *ngIf="manageTutorialHeading">Extra Taxes</h1>
        <h1 *ngIf="manageConvenienceHeading">Manage Convenience Fee</h1>
        <h1 *ngIf="manageDiseasesHeading">Crash Analytics</h1>
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading" style="color: white;">Change Password</h1>
        <h1 *ngIf="manageInterestdHeading">Google Analytics</h1>
        <h1 *ngIf="manageLocalHeading">Manage Local</h1>
        <h1 *ngIf="broadcastNotificationHeading">Broadcast Notifications</h1>


        
    </div>

    <div *ngIf="roleId === 4" class="right-sec" style="display: flex; align-items: center;">
        <img [src]="profilePic" alt="Profile Picture" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 10px;">
        <div>
          {{userName}}  {{lasName}}
        </div>
      </div>
      
      
    
    


</div>


<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>

</ng-template>