import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-loader',
  templateUrl: './manage-loader.component.html',
  styleUrls: ['./manage-loader.component.scss']
})
export class ManageLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
