import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
  selector: 'app-manage-invoice',
  templateUrl: './manage-invoice.component.html',
  styleUrls: ['./manage-invoice.component.scss']
})
export class ManageInvoiceComponent implements OnInit {

  constructor(
    private _header: HeaderService,

  ) { }

  ngOnInit(): void {
    this._header.manageInvoiceHeading.next(true);

  }

  ngOnDestroy() {
    this._header.manageInvoiceHeading.next(false);
  }

}
