import { Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('templateImage1', { static: true }) templateImage1: TemplateRef<any>;
  modalRef: BsModalRef;
  dashboardData: any[] = [];
  months: number = 6;
  currentUser: any;
  RegisteredCustomers: number;
  RegisteredBookings: number;
  totalRevenue: any;

  constructor(
    private headerService: HeaderService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private http: HttpClient,
    private router: Router
  ) { }

  page: number;
  itemsPerPage: number = 10;
  currentPage: number;
  selectedFilter: string = 'ALL';
  isQuickBooksConnected: boolean = false;

  ngOnInit(): void {
    this.headerService.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.route.queryParams.subscribe(params => {
      this.page = parseInt(params['page'], 10);
      if (isNaN(this.page)) {
        this.page = 1;
      }
    });

    this.currentPage = this.page || 1;
    this.getReportingList(this.currentPage, this.itemsPerPage, this.selectedFilter);
    // this.openModal(this.templateImage1);

    this.isQuickBooksConnected = localStorage.getItem('isQuickBooksConnected') === 'true';
    if (!this.isQuickBooksConnected) {
      this.openModal(this.templateImage1);
    }
    


  //   this.router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd && this.modalRef) {
  //       this.modalRef.hide();
  //     }
  //   });
  // }
  this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
      });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    console.log('Modal opened:', template); // Debugging log
  }

  ngOnDestroy() {
    this.headerService.welcomeUserMsg.next(false);
  }

  clearFilters(): void {
    this.startDate = '';
    this.endDate = '';
    this.dateError = false;

    this.onDateChange(); // Reset any dependent logic on date change
    this.getReportingList(this.page,this.itemsPerPage,this.selectedFilter); // Call the API to reload the default list
  }

  getReportingList(page: number, pageLimit: number, type: string, from?: string, to?: string) {
    // Prepare the payload
    const data: any = { 
        filterType: type.toUpperCase() 
    };

    // Add 'from' and 'to' to the payload only if they are provided
    if (from && to) {
        data.from = from;
        data.to = to;
    }

    console.log("Payload Data:", data); // Log the data to ensure 'from' and 'to' are included if set

    // Call the service to fetch data
    this.dashboardService.getDashboard(data).subscribe(
      (res: any) => {
        if (res.status) {
          this.RegisteredCustomers = res.data.totalUsers;
          this.RegisteredBookings = res.data.totalBookings;
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
}

  quickData: any;
  onDateChange() {
    if (this.startDate && this.endDate) {
      // Validate the date range
      if (new Date(this.endDate) < new Date(this.startDate)) {
        this.dateError = true;
        return;
      } else {
        this.dateError = false;
      }
    }

    // Call the method only if dates are valid
    if (this.selectedFilter && this.startDate && this.endDate && !this.dateError) {
      const formattedStartDate = this.startDate;
      const formattedEndDate = this.endDate;

      this.getReportingList(1, 10, this.selectedFilter, formattedStartDate, formattedEndDate);
    }
  }

  
  startDate: string;
  endDate: string;
  
  today: string = new Date().toISOString().split('T')[0];
  dateError: boolean = false;
  quickBooks() {
    this.dashboardService.getQuickBooksData().subscribe(
      (res: any) => {
        if (res.status) {
          window.location.href = res.data;
          localStorage.setItem('isQuickBooksConnected', 'true');
          this.isQuickBooksConnected = true;
          this.modalRef.hide();
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );

  }
  oAuthApiCall(apiUrl): any {
    this.http.get<any>(apiUrl).subscribe(
      (res: any) => {
        console.log('APIresp', res)
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  quickCallBooks() {
    this.dashboardService.getQuickBooksOtherData().subscribe(
      (res: any) => {
        if (res.status) {
          this.quickData = res.data;
          // this.quickData = res.data;

        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );

  }

  reset() {
    localStorage.removeItem('isQuickBooksConnected');
    this.isQuickBooksConnected = false;
  }

  onChangeCategory(selected: string) {
    this.selectedFilter = selected.toUpperCase();
    if (this.selectedFilter !== 'SELECTDATERANGE') {
      this.getReportingList(this.page, this.itemsPerPage, this.selectedFilter);
    }
  }

  getFormattedNumber(value: number) {
    const formattedNumber = value.toLocaleString('en-US');
    document.getElementById("client12").innerHTML = formattedNumber;
    console.log(formattedNumber);
    return formattedNumber;
  }

  getCounter() {
    const formattedNumber = this.totalRevenue.toLocaleString('en-US');
    document.getElementById("client1").innerHTML = formattedNumber;
    console.log(formattedNumber);
  }

  pageChanged(event: PageChangedEvent): void {
    this.page = event.page;
    this.getReportingList(event.page, event.itemsPerPage, this.selectedFilter);
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);
  }
}
