import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/auth/services/auth.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  userEmail: string;
  role: number;
  template: TemplateRef<any>;
  currentUser: any;
  rolesCheck: any;
  roleId: any;
  mainEmailId: any;
  isSettingsSubMenuOpen: boolean = false;
  isAnalyticsSubMenuOpen: boolean = false;
  isChangePasswordSelected: boolean = false;
  isUpdateProfileSelected: boolean = false;
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.roleId = this.currentUser?.profile?.role;
    this.rolesCheck = this.currentUser?.profile?.permissions;
    console.log('rolesCheck', this.rolesCheck)
    this.mainEmailId = this.currentUser?.email;
    this.userEmail = 'amni@yopmail.com';

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setSubMenuState();
      });

    this.setSubMenuState();
  }

  setSubMenuState() {
    const currentUrl = this.router.url;
    this.isSettingsSubMenuOpen = currentUrl.includes('manage-changePassword') || currentUrl.includes('manage-updateProfile') || currentUrl.includes('manage-settings');
    this.isAnalyticsSubMenuOpen = currentUrl.includes('manage-crash') || currentUrl.includes('manage-analytics');
  }

  toggleSubMenu(submenu: string) {
    if (submenu === 'analytics') {
      this.isAnalyticsSubMenuOpen = !this.isAnalyticsSubMenuOpen;
    }
    if (submenu === 'settings') {
      this.isSettingsSubMenuOpen = !this.isSettingsSubMenuOpen;
    }
  }

  highlightChangePassword(event: Event) {
    event.stopPropagation();
    this.isChangePasswordSelected = true;
    this.isUpdateProfileSelected = false;
  }

  highlightUpdateProfile(event: Event) {
    event.stopPropagation();
    this.isChangePasswordSelected = false;
    this.isUpdateProfileSelected = true;
  }

  logout() {
    this.modalRef.hide();
    localStorage.removeItem("currentUser");
    this.router.navigateByUrl("/auth/login");
  }

  confirm() {
    this.logout();
  }

  cancel() {
    this.modalRef.hide();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
}
