import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-quick-books',
  templateUrl: './quick-books.component.html',
  styleUrls: ['./quick-books.component.scss']
})
export class QuickBooksComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private http: HttpClient,
    private modalService: BsModalService

  ) { }

  ngOnInit(): void {
    // this.headerService.welcomeUserMsg.next(true);

    this.handleQuickBooksCallback();
    
  }
  isLoading: boolean = true;
  handleQuickBooksCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');
    
    if (authCode) {
        this.quickCallBooks(authCode);
    }
  }

  // quickCallBooks(authCode: string) {
  //   this.authService.getQuickBooksOtherData(authCode).subscribe(
  //     (res: any) => {
  //       if (res.status) {
  //         this.toastr.success('Connected to QuickBooks successfully');
  //         this.closeModal(); // Close the modal
  //         this.router.navigateByUrl('/analytics-dashboard');
  //       } else {
  //         this.toastr.success('Connected to QuickBooks successfully');
  //         this.closeModal(); // Close the modal
  //         this.router.navigateByUrl('/analytics-dashboard');
  //       }
  //     },
  //     (error) => {
  //       console.error('Error:', error);
  //       this.toastr.error('An error occurred while connecting to QuickBooks');
  //     }
  //   );
  // }

  quickCallBooks(authCode: string) {
    this.authService.getQuickBooksOtherData(authCode).subscribe(
      (res: any) => {
        if (res.message) {
          this.toastr.success('Connected to QuickBooks successfully');
          this.closeModal(); // Close the modal
          this.router.navigateByUrl('/analytics-dashboard');
        } 
      },
      (error) => {
        console.error('Error:', error);
        this.toastr.error('An error occurred while connecting to QuickBooks');
      }
    );
  }
  

  closeModal() {
    this.modalService.hide(); // Close the modal
  }

  // quickCallBooks() {
  //   this.authService.getQuickBooksOtherData().subscribe(
  //     (res: any) => {
  //       if (res.status) {
  //         window.location.href = res.data;
  //         // this.quickData = res.data;

  //       }
  //     },
  //     (error) => {
  //       console.error('Error:', error);
  //     }
  //   );

  // }

}
