<!-- <app-layout></app-layout> -->
<!-- 
<div class="black-overlay">
  <div class="loader"></div>
  <img src="../../../../assets/new images/login-upd.png" alt=""/>
</div> -->

<div class="black-overlay">
  <img src="../../../../assets/new images/login-upd.png" alt=""/>
  <div class="loader"></div>
</div>

  

